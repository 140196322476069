import { useEffect } from "react";
import bpa from "../assets/images/bpa.webp";
import iaf from "../assets/images/iaf.webp";
import ias from "../assets/images/ias.webp";
import rir from "../assets/images/rir.webp";
import map from "../assets/images/map.webp";
import ith from "../assets/images/ith.webp";
import fth from "../assets/images/fth.webp";
import ft from "../assets/images/ft.webp";
import fo from "../assets/images/fo.webp";
import io from "../assets/images/io.webp";
import it from "../assets/images/it.webp";
import ff from "../assets/images/ff.webp";
import rt from "../assets/images/rt.webp";
import ro from "../assets/images/ro.webp";
import st from "../assets/images/st.webp";
import sth from "../assets/images/sth.webp";
import so from "../assets/images/so.webp";
import legalOne from "../assets/images/legalOne.webp";
import legalTwo from "../assets/images/legalTwo.webp";

const preloadImages = (images) => {
  images.forEach((src) => {
    const img = new Image();
    img.src = src;
  });
};

const assets = {
  legalOne,
  legalTwo,
  so,
  sth,
  st,
  ro,
  rt,
  ff,
  it,
  io,
  ith,
  fth,
  ft,
  fo,
  bpa,
  iaf,
  ias,
  rir,
  map,
};

const AssetLoader = () => {
  useEffect(() => {
    // Preload all the images
    preloadImages(Object.values(assets));
  }, []);

  return null; // This component doesn't need to render anything
};

export { assets, AssetLoader };
