import React from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";
import { assets } from "../../assets/assets";

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="firstPart">
          <div className="logof">
            <div className="firstl">
              <img srcSet={assets.bpa} alt="B.P. Alloys Limited" />
              <h3>Since&nbsp;1987</h3>
            </div>
            <div className="secondl">
              <h1>B.P.&nbsp;ALLOYS&nbsp;LIMITED</h1>
              <div className="linethree"></div>
              <h3>
                Manufacturer&nbsp;of&nbsp;Special&nbsp;Grade&nbsp;Alloy&nbsp;Steel
              </h3>
            </div>
          </div>
          <div className="partner">
            <div className="onelinel">
              <img srcSet={assets.rir} alt="B.P. Alloys Limited" />
              <img srcSet={assets.ias} alt="B.P. Alloys Limited" />
              <img srcSet={assets.iaf} alt="B.P. Alloys Limited" />
            </div>
            <div className="cert">
              An&nbsp;ISO&nbsp;9001&nbsp;:&nbsp;2015&nbsp;Certified&nbsp;Co.
            </div>
          </div>
        </div>
        <div className="footnav">
          <NavLink exact to="/" activeClassName="active" className="navLinkF">
            HOME
          </NavLink>

          <NavLink
            exact
            to="/about"
            activeClassName="active"
            className="navLinkF"
          >
            ABOUT&nbsp;US
          </NavLink>

          <NavLink
            exact
            to="/service"
            activeClassName="active"
            className="navLinkF"
          >
            SERVICES
          </NavLink>

          <NavLink to="/contact" activeClassName="active" className="navLinkF">
            CONTACT&nbsp;US
          </NavLink>
        </div>
        <div className="line"></div>
        <div className="copyright">
          <p>
            Copyright&nbsp;©&nbsp;2024&nbsp;B.P.&nbsp;ALLOYS&nbsp;LIMITED.&nbsp;All&nbsp;rights&nbsp;reserved.
          </p>
          <a href="https://www.linkedin.com/in/prakhar5/" target="_blank">
            Connect developer
          </a>
        </div>
      </div>
    </>
  );
}

export default Footer;
