import "./Contact.css";
import React from "react";
import { assets } from "../../assets/assets";
import Navbar from "../Navbar/Navbar";

function Contact() {
  return (
    <>
      <div className="contact">
        <Navbar />
        <div className="overlayc"></div>
        <div className="content">
          <div className="buttonone">CONTACT&nbsp;US</div>
          <div className="head textgradient">
            Get&nbsp;in&nbsp;touch&nbsp;with&nbsp;us&nbsp;!
          </div>
          <div className="contactinfo">
            <a href="tel:01612972640" className="phoneone">
              <i className="fa-solid fa-phone"></i>
              <div className="name">PHONE</div>
              Ph.&nbsp;:&nbsp;0161-2972640
            </a>
            <div className="linetwo"></div>
            <a
              href="https://maps.app.goo.gl/sgwn1tqHHQVTX8rd7"
              className="phonetwo"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-solid fa-map-location-dot"></i>
              <div className="name">ADDRESS</div>
              Regd.&nbsp;Office&nbsp;&&nbsp;Works:&nbsp;B-52/D-252,
              Phase&nbsp;VII, Focal Point, 141010,
              Ludhiana,&nbsp;Punjab,&nbsp;India.
            </a>
            <div className="linetwo"></div>
            <a href="mailto:info@bpalloys.co.in" className="phonethree">
              <i className="fa-solid fa-envelope-open-text"></i>
              <div className="name">EMAIL</div>
              info@bpalloys.co.in
            </a>
          </div>
          <a
            href="https://www.linkedin.com/company/bp-alloys-limited/"
            className="linkedin"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-linkedin textgradient"></i>
            LinkedIn&nbsp;Corporation
          </a>
          <a
            href="https://maps.app.goo.gl/sgwn1tqHHQVTX8rd7"
            className="map"
            target="_blank"
            rel="noreferrer"
          >
            <img srcSet={assets.map} alt="B.P. Alloys Limited" />
          </a>
        </div>
      </div>
    </>
  );
}

export default Contact;
