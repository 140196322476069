import React, { useLayoutEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Lenis from "@studio-freight/lenis";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";

function Contactpage() {
  const lenis = new Lenis({
    duration: 2,
  });

  lenis.on("scroll", (e) => {
    console.log(e);
  });

  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);

  useLayoutEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Contact />
      <Footer />
    </>
  );
}

export default Contactpage;
